@font-face {
    font-family: "mister";
    src: url("./fonts/Mister-Brown.otf") format("otf"),
         url("./fonts/mister-brown.ttf") format('ttf'),
         url("./fonts/mister-brown.woff") format("woff");;
}

@font-face{
    font-family: "roboto";
    src: url("./fonts/Roboto-Regular.ttf") format("ttf");
}

@font-face{
    font-family: "HelveticaNeue-Medium";
    src: url("./fonts/Helvetica/HelveticaNeue-Medium.otf") format("oft");
}

@font-face {
  font-family: "graphie-bold";
  src: url("./fonts/Graphie/Graphie-Bold.otf") format("oft");
}

@font-face {
  font-family: "graphie-extra-bold";
  src: url("./fonts/Graphie/Graphie-ExtraBold.otf") format("oft");
}

@font-face {
  font-family: "graphie-book";
  src: url("./fonts/Graphie/Graphie-Book.otf") format("oft");
}


body[data-theme='renault']{
  --background-color:white;
  --boder-color:rgb(38, 38, 38);
  --text-color:#000;
  --color-red:#FC4F5C;
  --color-btn:#f45000;
  --primary-color : #f45000;
  --color-btn-hover:#db4a01;
  --background-hover: #db4a01;
  --color-green:rgb(86, 160, 86);
  --background-list: #f4f4f4;
  --user-pseudo: black;
}



body{
  font-family: "graphie-book";
  background-color: var(--background-color);
  color:var(--text-color)
}
h1{
  font-family: "graphie-extra-bold";
  font-weight: bold;
}



p{margin: 0;font-size: .9rem;}

.input-text{
  border:none; 
  border-bottom: 1px solid rgb(205, 205, 205);
  resize: none;
  outline: none;
  width:100%;
  box-shadow: border-box;
  height: 40px;
  margin:10px 0;
  transition: 700ms;
}


.list-group{margin: 5px 15px;}

.input-text:focus{border-bottom: 1px solid var(--color-btn);}
textarea{background-color: var(--background-color);color:var(--text-color)}

.bg-list{background-color: var( --background-list);margin: 52px 0;border-radius: 10px!important;}
.list-group-item{border: none; min-height: 138px; }
input[type='file']{display: none;}

.img-user{
  width:40px;
  height: 40px;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.img-user-comment{
  width:26px;
  height: 26px;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.img-user img,.img-user-comment img{width:100%;}
.user-twitte{margin-left: 5px;}
.user-pseudo{font-size: 18px;color:var(--user-pseudo);font-weight: bold;}
.user-pseudo.pseudo-comment{font-size: 15px;}
.user-content-comment{flex-grow: 1;}



.container-comment{padding-left: 55px;margin-top: 10px;}
.container-comment p{font-size: .9rem;}
.content-comment-data{flex-grow: 1;}



.btn-add-picture{
  background-color: var(--color-btn);
  cursor: pointer;
  padding: 3px;
}
.btn-tweet{background-color: var(--color-btn);color: white;}
.button{
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 100%;
  border:none;
  text-align: center;
  background-color: transparent;
  cursor: pointer;
}
/* .button:hover{background-color:var(--background-hover)} */
.btn.disabled, .btn:disabled, fieldset:disabled .btn{background-color: gray;}

.reply{margin-left: 10px;}
.svg-reply{fill: var(--text-color);}
.svg-delete{fill:rgb(240, 191, 191) ;}


.svg-like,.svg-comment{
    fill: none;
    stroke: var(--color-btn);
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 3px;
    color: #f9d2bf;
}




.like-comment{display: flex;align-items: center;margin-right: 10px;}
.like-comment .button-like{margin-right: 0;width: 20px;}
button:focus{outline: none;}

.change-color{
  position:fixed;
  bottom:20px;
  right:20px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: var(--color-btn);
  text-align: center;
  transition: 700ms;
  color:white;
  cursor: pointer;
  line-height: 30px;
}

.change-color:hover{background-color: var(--color-btn-hover); color:white;}
.color-spinner{background-color: var( --color-btn);}

.miniature{width:100%;}

p.date,p.user-link{font-size: 12px;}
.content-twitte-img{display:flex;flex-wrap: wrap;}
.content-twitte-img img,.img-modal-button{cursor: pointer;}

.card-miniature{position: relative;}
.btn-remove-img{position: absolute;top:2px;}

/* .red-svg{fill:rgb(242, 61, 61)} */

.red-svg,.comment-like{fill:var(--color-btn);color:var(--color-btn)}
.green-svg{fill:var(--color-green)}
.red{color:var(--color-btn)}
.green{color:var(--color-green)}
.primary-color{color:var(--primary-color)}
small.green{font-size: .7rem;}

.color-hashtag{color:rgb(147, 168, 252)}
.img-slider{width:100%;}


.btnViewBox{fill:white;position:absolute;transform: translateY(-50%);top:50%;cursor: pointer;}
.btn-next {
  right: 15px;
}
.moon{fill:white;}


.container-center{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  padding: 0 20px;
}

.cursor{cursor: pointer;}
.logout-container{
  margin-top: 5px;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  margin-right: 15px;
}

.video-container{position: relative;}
video{width: 100%;margin: 5px 0;}
.video-close{
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 10px;
}


.button-like,.button-reply{margin-right: 5px;}

@media only screen and (max-width:576px) {

  .form-action{width: 95%;margin-left: 5px;}
  .content-twitte p{margin: 10px 0;}

}